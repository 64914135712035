import { render, staticRenderFns } from "./BlogAdminPreview.vue?vue&type=template&id=85713ad2&scoped=true&"
import script from "./BlogAdminPreview.vue?vue&type=script&lang=js&"
export * from "./BlogAdminPreview.vue?vue&type=script&lang=js&"
import style0 from "./BlogAdminPreview.vue?vue&type=style&index=0&id=85713ad2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85713ad2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VAlert,VBtn,VCard,VCardSubtitle,VCardText,VCardTitle,VCol,VContainer,VIcon,VRow,VSheet,VSkeletonLoader})
